

html, body, #root{
  max-width: 100vw;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
}

*{
  box-sizing: border-box;
}


